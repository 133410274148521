// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/Roboto/Roboto-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/Roboto/Roboto-Italic.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../assets/fonts/Roboto/Roboto-Bold.ttf";
import ___CSS_LOADER_URL_IMPORT_3___ from "../assets/fonts/Roboto/Roboto-BoldItalic.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'roboto-regular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");\n  font-style: normal; }\n\n@font-face {\n  font-family: 'roboto-italic';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");\n  font-style: normal; }\n\n@font-face {\n  font-family: 'roboto-bold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\");\n  font-style: normal; }\n\n@font-face {\n  font-family: 'roboto-bold-italic';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");\n  font-style: normal; }\n", "",{"version":3,"sources":["webpack://src/styles/styles.scss"],"names":[],"mappings":"AAEA;EACC,6BAA6B;EAC7B,2DAAoE;EACpE,kBAAkB,EAAA;;AAGnB;EACC,4BAA4B;EAC5B,2DAAmE;EACnE,kBAAkB,EAAA;;AAGnB;EACC,0BAA0B;EAC1B,2DAAiE;EACjE,kBAAkB,EAAA;;AAGnB;EACC,iCAAiC;EACjC,2DAAuE;EACvE,kBAAkB,EAAA","sourcesContent":["// ROBOTO\n\n@font-face {\n\tfont-family: 'roboto-regular';\n\tsrc: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('woff');\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: 'roboto-italic';\n\tsrc: url('../assets/fonts/Roboto/Roboto-Italic.ttf') format('woff');\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: 'roboto-bold';\n\tsrc: url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('woff');\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: 'roboto-bold-italic';\n\tsrc: url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('woff');\n\tfont-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
